<template>
  <section class="hero is-fullheight">
    <ModalMessage
      ref="modalMessageRef"
      v-if="activeModalMessage"
      :active="activeModalMessage"
      :loading="loading"
      :currentTab="currentTab"
      :currentDataModal="currentDataModal"
      :isPublications="publications"
      :isProduct="products"
      @close-modal="closeModal"
      @update-status-modal="updateStatusModal"
    />
    <div class="hero-body">
      <div class="container">
        <div class="columns is-multiline">
          <div class="column is-12">
            <div class="tabs">
              <ul>
                <li
                  v-for="tab in tabList" :key="tab.id" @click="changeTab(tab.id)"
                  :class="{ 'is-active' : tab.id === currentTab }"
                >
                  <a>{{ tab.name }}</a>
                </li>
              </ul>
            </div>
          </div>
          <div class="column is-12">
            <publication-message-main
              ref="publicationMessageRef"
              v-if="currentTab === publications"
              @show-modal-publication="showModalPublication"
            />
            <product-message-main
              ref="productMessageRef"
              v-if="currentTab === products"
              @show-modal-product="showModalProduct"
            />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'MessagesMain',
  components: {
    PublicationMessageMain: () => import('@/components/Messages/Publications/PublicationMessageMain.vue'),
    ProductMessageMain: () => import('@/components/Messages/Products/ProductMessageMain.vue'),
    ModalMessage: () => import('@/components/Messages/ModalMessage.vue')
  },
  data () {
    return {
      loading: false,
      currentTab: 1,
      publications: 1,
      products: 2,
      tabList: [
        { id: 1, name: 'PUBLICACIONES' },
        { id: 2, name: 'PRODUCTOS' }
      ],
      activeModalMessage: false,
      currentDataModal: null
    }
  },
  methods: {
    changeTab (tabId) {
      this.currentTab = tabId
      // Se resetea la URL
      this.$router.push({ name: 'mensajes' }).catch(() => {})
    },
    showModalPublication (data) {
      this.activeModalMessage = true
      this.currentDataModal = data
    },
    showModalProduct (data) {
      this.activeModalMessage = true
      this.currentDataModal = data
    },
    closeModal () {
      this.activeModalMessage = false
      this.currentDataModal = null
    },
    async updateStatusModal (idMessage) {
      if (this.currentTab === this.publications) {
        this.loading = true
        const resp = await this.$refs.publicationMessageRef.updateStatusMessagePublicationAction(idMessage)
        if (!resp) {
          this.$refs.modalMessageRef.resetStatus()
        }
        this.loading = false
      } else {
        this.loading = true
        const resp = await this.$refs.productMessageRef.updateStatusMessageProductAction(idMessage)
        if (!resp) {
          this.$refs.modalMessageRef.resetStatus()
        }
        this.loading = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  @media screen and (max-width: 768px) {
    .tabs ul {
      flex-direction: column;
      li, a {
        width: 100%;
      }
    }
  }
</style>
